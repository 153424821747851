import request from "@/utils/request";

export function getCodeLibQuery(classcode) {
  return request({
    url: "/api/v1/codelib-query",
    method: "get",
    cache: {
      type: "local",
      ttl: 24 * 3600,
    },
    once: true,
    params: {
      key: "",
      undefault: "F",
      classcode,
    },
  });
}

// 获取文件分类
export function getFileCategory() {
  return getCodeLibQuery("DOCTYPE");
}

// 获取货物类型
export function cargonatureOptions(key) {
  return request({
    url: "/api/v1/dict/cargonature",
    method: "get",
    params: {
      key: key,
    },
  });
}
// 获取箱型
export function boxTypeOptions(key) {
  return request({
    url: "/api/v1/dict/boxtype",
    method: "get",
    params: {
      key: key,
    },
  });
}
// 获取箱型尺寸
export function boxSizeOptions(key) {
  return request({
    url: "/api/v1/dict/boxsize",
    method: "get",
    params: {
      key: key,
    },
  });
}

// 获取vgmunit
export function vgmunitOptions(key) {
  return request({
    url: "/api/v1/dict/vgmunit",
    method: "get",
    params: {
      key: key,
    },
  });
}
// 获取vgmmethod
export function vgmmethodOptions(key) {
  return request({
    url: "/api/v1/dict/vgmmethod",
    method: "get",
    params: {
      key: key,
    },
  });
}
// 获取voluneunit
export function voluneunitOptions(key) {
  return request({
    url: "/api/v1/dict/voluneunit",
    method: "get",
    params: {
      key: key,
    },
  });
}

// 获取weightunit
export function weightunitOptions(key) {
  return request({
    url: "/api/v1/dict/weightunit",
    method: "get",
    params: {
      key: key,
    },
  });
}

export function wraptypeOptions(key) {
  return request({
    url: "/api/v1/dict/wraptype",
    method: "get",
    params: {
      key: key,
    },
  });
}

export function quantityunitOptions(key) {
  return request({
    url: "/api/v1/dict/quantityunit",
    method: "get",
    params: {
      key: key,
    },
  });
}


// 币别下拉
export function getCurrencyOptions(key = "") {
  return request({
    url: "/api/v1/currency-query",
    method: "get",
    params: {
      key,
    },
    once: true,
  });
}

export function getCartType({key = "", inout = ""}) {
  return request({
    url: "/api/v1/cartype-query",
    method: "get",
    params: {
      key,
      inout
    },
    cache: {
      type: "session",
    },
    once: true,
  });
}


// 海运运输条款下拉
export function getServiceTypeOptions({key = ""}) {
  return request({
    url: "/api/v1/servicetype-option",
    method: "get",
    params: {key}
  });
}

// 业务员下拉选择
export function getClerkOptions (){
  return request({
    url:'api/v1/dialog-mySalerlist',
    method:'get',
  })
}

// 获取运输条款
export function getTermsListOptions(key) {
  return request({
    url: "/api/v1/dict/transportationterms",
    method: "get",
    params: {
      key: key,
    },
  });
}
