import request from '@/utils/request'

// 查运价
export function fetchPriceFclList(query) {
  return request({
    url: '/api/v1/shippingprice-list2',
    method: 'post',
    once: true,
    params: query 
  }).then((response) => {
    return response
  })
}

// 查目的港免用箱
export function fetchFreedetentionList(query) {
  return request({
    url: '/api/v1/freedetentions',
    method: 'post',
    once: true,
    params: query 
  }).then((response) => {
    if (response.data?.Items) {
      console.log(response.data?.Items)
    }

    return response
  })
}

// 查目的港免用箱
export function fetchLocalCostList(query) {
  return request({
    url: '/api/v1/localcosts',
    method: 'post',
    once: true,
    params: query 
  }).then((response) => {
    if (response.data?.Items) {
      
    }

    return response
  })
}

// 获取起运站/目的站
export function getRailStations() {
  return request({
    url: '/api/v1/railport-query',
    method: 'get',
    params: {
      key: '',
      type: ''
    },
    once: true
  })
}


// 提交订单
export function AddOrder(data) {
  return request({
    url: '/api/v1/addOrder',
    method: 'post',
    data: {
      orderInfo:data
    },
  });
}

// 更新订单
export function UpdateOrder(data) {
  return request({
    url: '/api/v1/UpdateOrder',
    method: 'post',
    data: {
      orderInfo:data
    },
  })
}

// 保存订单指示
export function SaveOrderInstruct(data) {
  return request({
    url: '/api/v1/saveorderinstruct',
    method: 'post',
    data: {
      orderinstruct:data
    },
  })
}


// 获取订单指示
export function getorderinstruct(id) {
  return request({
    url: '/api/v1/getorderinstruct',
    method: 'get',
    params: {
      shiporderid:id
    },
  })
}

// 保存集装箱货物
export function SaveCargoAndGoods(data) {
  return request({
    url: '/api/v1/savecargogoods',
    method: 'post',
    data: {
      cargogoodses:data
    },
  })
}


// 获取集装箱获取信息
export function getCargoAndGoods(shporderno) {
  return request({
    url: '/api/v1/getcargogoods',
    method: 'get',
    params: {
      shporderno:shporderno
    },
  })
}

// 获取提单确认件
export function PreviewSumbitConfirmBill(shporderno) {
  return request({
    url: '/api/v1/preview-submit-bill',
    method: 'get',
    headers:{
      responseType:'Blob'
    },
    params: {
      shporderno:shporderno
    },
  })
}

// 获取cosco VGM
export function Previewcoscovgm(shporderno) {
  return request({
    url: '/api/v1/preview-cosco-vgm',
    method: 'get',
    headers:{
      responseType:'Blob'
    },
    params: {
      shporderno:shporderno
    },
  })
}

// 提交材料
export function submitmaterial(shporderno) {
  return request({
    url: '/api/v1/submitmaterial',
    method: 'get',
    params: {
      shporderno:shporderno
    },
  })
}

// 获取材料
export function getmaterials(shporderno) {
  return request({
    url: '/api/v1/getmaterials',
    method: 'get',
    params: {
      shporderno:shporderno
    },
  })
}

// 下载材料
export function downloadmaterials(id) {
  return request({
    url: '/api/v1/downloadmaterials',
    method: 'get',
    params: {
      materialid:id
    },
  })
}


// 上传材料
export function uploadmaterials(data) {
  return request({
    url: '/api/v1/uploadmaterials',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: data,
  })
}


// 上传材料
export function deletematerials(id) {
  return request({
    url: '/api/v1/deletematerials',
    method: 'get',
    params: {
      materialid:id
    },
  })
}

// 获取装货港/卸货港
export function SeaPortOptions(key) {
  return request({
    url: '/api/v1/seaport-query',
    method: 'post',
    params: {
      key
    },
    once: true
  })
}

// 获取装货港/卸货港
export function Cargonatures() {
  return request({
    url: '/api/v1/dict/cargonature',
    method: 'get',
    once: true
  })
}

// 获取装货港/卸货港
export function deleteOrder(id) {
  return request({
    url: '/api/v1/order/'+id,
    method: 'get',
    once: true,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
}

export function sendmail1() {
  return request({
    url: '/api/v1/sendmail',
    method: 'get',
    once: true,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
}


