import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
import BaseLayout from '@/views/layout/Base'
import OrderLayout from '@/views/user/OrderLayout'
import WorkBenchLayout from '@/views/user/WorkBenchLayout'
import Page404 from '@/views/state/Page404'

const routes = [
  {
    path: '',
    component: BaseLayout,
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/home/Home')
      },
      {
        path: 'home',
        redirect: '/'
      },
      {
        path: '/workflow',
        name: 'workflow',
        component: () => import('@/views/Workflow')
      },
      {
        path: '/payways',
        name: 'payways',
        component: () => import('@/views/Payways')
      },
      // {
      //   path: 'business/track/:type',
      //   name: 'BusinessTrack',
      //   component: () => import('@/views/business/Tracker')
      // },
      // {
      //   path: 'satisfied',
      //   name: 'WorkOrderSatisfied',
      //   component: () => import('@/views/user/order/Satisfied')
      // }
      // {
      //   path: "preview/template/:type",
      //   name: "PreviewTemplate",
      //   component: () => import("@/views/preview/View"),
      // },
    ]
  },
  // {
  //   path: '/track/share/:orderNum/:jobNo/:conNo',
  //   component: TrackViewer
  // },
  {
    path: '/user',
    name: 'PermissionRouteToCheckUser',
    // 检测认证
    component: () => import('@/views/layout/Permission'),
    children: [
      {
        path: 'freight/query',
        name: 'FreightQuery',
        component: () => import('@/views/freight/Query')
      },
      {
        path: 'freight/order',
        name: 'FreightOrder',
        component: () => import('@/views/freight/Order')
      }
      ,{
        path: 'freight/freedetention',
        name: 'FreedetentionQuery',
        component: () => import('@/views/freedetention/Query')
      }
      ,{
        path: 'freight/localcost',
        name: 'LocalCostQuery',
        component: () => import('@/views/localcost/Query')
      },
    ]
  },
  {
    path: '/user',
    component:WorkBenchLayout,
    children:[
      {
        path: 'workBench/common/MySubscribe',
        name: 'MySubscribe',
        component: () => import('@/views/user/workBench/MySubscribe')
      },
    ]
  },
  {
    path: '/user',
    component: BaseLayout,
    children: [
      {
        path: 'order',
        name: 'UserOrderBase',
        component: OrderLayout,
        children: [
          {
            path: '',
            name: 'WorkPlace',
            component: () => import('@/views/user/order/Index')
          },
          // {
          //   path: 'userOrder/booking',
          //   name: 'UserOrder',
          //   component: () => import('./views/user/order/UserOrder')
          // },
          {
            path: 'booking/:type',
            name: 'UserOrderBookingQuery',
            component: () => import('@/views/user/order/BookingQuery')
          },
          {
            path: 'booking/:type/history',
            name: 'UserOrderHisBookingQuery',
            component: () => import('@/views/user/order/HisBookingQuery')
          },
          {
            path: 'freight/common/query',
            name: 'UserOrderFreightQuery',
            component: () => import('@/views/user/order/FreightQuery')
          },

          {
            path: 'booking/:type/info',
            name: 'UserOrderInfo',
            component: () => import('@/views/user/order/OrderInfo')
          },
          {
            path: 'booking/:type/fillOrderDetail',
            name: 'UserOrderFillDetailInfos',
            component: () => import('@/views/user/order/FillDetailInfos')
          },
          {
            path: 'booking/:type/materialmanagement',
            name: 'UserOrderMaterialManagement',
            component: () => import('@/views/user/order/MaterialManagement')
          },
          // {
          //   path: 'track-info/:trafficCd',
          //   name: 'TrackInfo',
          //   component: () => import('@/views/user/order/TrackInfoRailway')
          // },
          {
            path: 'unopen/common/:type',
            name: 'UnOpenPage',
            component: () => import('@/views/user/order/UnopenPage')
          },
          {
            path: 'workOrder/:type',
            name: 'WorkOrderQuery',
            component: () => import('@/views/user/order/WorkOrderQuery')
          },
          // {
          //   path: 'workOrder/:type/info/:trafficCd',
          //   name: 'WorkOrderInfo',
          //   component: () => import('@/views/user/order/WorkOrderInfo')
          // },
          {
            path: 'workBench/common/todo',
            name: 'WorkOrderTodo',
            component: () => import('@/views/user/order/WorkOrderTodo')
          },
          // {
          //   path: 'workBench/common/notice',
          //   name: 'WorkOrderNotice',
          //   component: () => import('@/views/user/commonPage/WorkOrderNotice')
          // },
          {
            path: 'workBench/common/billDetail/:id',
            name: 'MyBillDetail',
            component: () => import('@/views/user/commonPage/MyBillDetail')
          },
          
        ]
      },
      {
        path: '',
        name: 'UserLayout',
        component: () => import('@/views/user/UserLayout'),
        children: [
          {
            path: 'info',
            name: 'UserInformation',
            component: () => import('@/views/user/info/Index')
          },
        ]
      },
    ]
  },
  {
    path: '*',
    redirect: (to) => {
      return {
        name: 'Page404',
        query: {
          url: to.fullPath
        }
      }
      // return `/404?url=${encodeURIComponent(to.fullPath)}`
    }
  },
  {
    path: '/404',
    name: 'Page404',
    component: Page404
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.name === from.name) {
      return {}
    }
    return { x: 0, y: 0 }
  }
})

export default router
