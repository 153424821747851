<template>
  <div>
    <el-form :disabled="disabled" class="pagination-style">
      <el-pagination
          background
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="PAGE_SIZE"
          @size-change="onSizeChange"
          @current-change="onCurrentChange"
          :total="total"
          :layout="layout"/>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "YunPagination",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    total: Number,
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30];
      },
    },
    pageSize: [String, Number],
    layout: {
      type: String,
      default: "sizes, prev, pager, next, jumper",
    },
  },
  data() {
    return {
      PAGE_SIZE: this.pageSize ?? this.pageSizes[0],
    };
  },
  methods: {
    onSizeChange($event) {
      this.$emit("update:pageSize", $event);
      this.$emit("size-change", $event);
      this.$emit("page-change");
    },
    onCurrentChange($event) {
      this.$emit("update:currentPage", $event);
      this.$emit("current-change", $event);
      this.$emit("page-change");
    },
  },
};
</script>

<style scoped lang="scss">
.pagination-style {
  margin: 0;
}
</style>