<template>
  <el-radio v-model="dynamic" class="oms-radio-extend" v-bind="$attrs">
    <slot/>
  </el-radio>
</template>

<script>
import {model} from "@/mixins";

export default {
  name: "ElRadioExtend",
  props: ["value"],
  methods: {},
  mixins: [model],
};
</script>

<style lang="scss">
.oms-radio-extend {
  &.el-radio {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    .el-radio__input {
      display: none;
    }

    .el-radio__input.is-disabled + span.el-radio__label {
      color: #606266;
    }

    .el-radio__label {
      height: 40px;
      padding: 0 20px;
      line-height: 40px;
      border-radius: 5px;
      display: inline-block;
      border: 1px solid #DCDFE6;
      transition: .2s linear;
    }

    &.is-checked {
      .el-radio__label {
        background: #EDF4FF;
        border-color: #EDF4FF;
      }

      .el-radio__input.is-disabled + span.el-radio__label {
        color: #409EFF;
      }
    }
  }
}
</style>