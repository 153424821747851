class RequestAnimationMain {
  privateMethod = [];

  while_action() {
    try {
      this.privateMethod.forEach(method => method?.());
    } catch (e) {
      console.error(e);
    }
    requestAnimationFrame(this.while_action.bind(this));
  }

  set_action(methods) {
    this.privateMethod = this.privateMethod.concat(methods);
  }

  remove_action(methods) {
    this.privateMethod = this.privateMethod.filter(method => !methods.includes(method));
  }
}

const requestAnimation = new RequestAnimationMain();
requestAnimation.while_action();

let __METHOD_ACTION = [];
export default {
  beforeDestroy() {
    requestAnimation.remove_action(__METHOD_ACTION);
    __METHOD_ACTION = [];
  },
  methods: {
    AnimationCompose(...methods) {
      __METHOD_ACTION = __METHOD_ACTION.concat(methods.map(method => {
        if (method?.["execute"] === true) {
          method?.method?.();
          return method?.method;
        }

        return method;
      }));
      requestAnimation.set_action(__METHOD_ACTION);
    },
  },
};
