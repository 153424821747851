import {i18n} from "@/main";

export default {
  data() {
    return {
      HeaderInfo: {
        MobileLogin: {
          area: "",
          phone: "",
          verifyImgCode: "",
          verifySmsCode: "",
        },
      },
      bindUser: {
        area: "86",
        phone: "",
        verifySmsCode: "",
        type: "",
        account: "",
        password: "",
        newPassword: "",
        samePassword: "",
      },
      INFO_RULES: {},
    };
  },
  created() {
    this.refreshRules();
  },
  watch:{
    '$i18n.locale'(){
      this.refreshRules();
    }
  },
  methods: {
    refreshRules() {
      this.INFO_RULES = {
        MOBILE_RULES_PROP: {
          areaAndPhone: "areaAndPhone",
          verifyImgCode: "verifyImgCode",
          verifySmsCode: "verifySmsCode",
        },
        RESET_PASSWORD_PROP: {
          password: "password",
          newPassword: "newPassword",
          samePassword: "samePassword",
        },
        BIND_PHONE_RULES_PROP: {
          areaAndPhone: "areaAndPhone",
          verifySmsCode: "verifySmsCode",
        },
        BIND_ACCOUNT_PROP: {
          account: "account",
          password: "password",
        },
        RESET_PASSWORD: {
          password: [{required: true, message: i18n.t("RuleMessage.OldPassword"), trigger: "submit"}],
          newPassword: [
            {required: true, message: i18n.t("RuleMessage.NewPassword"), trigger: "submit"},
            {validator: this.checkPasswordLength, trigger: "submit"},
          ],
          samePassword: [
            // {required: true, message: "两次密码不一致", trigger: "submit"},
            {validator: this.samePassword, trigger: "submit"},
          ],
        },
        BIND_ACCOUNT: {
          account: [{
            required: true,
            message: i18n.t("RuleMessage.InputAccount"),
            trigger: "submit",
          }],
          password: [{
            required: true,
            message: i18n.t("RuleMessage.InputPassword"),
            trigger: "submit",
          }],
        },
        ACCOUNT_LOGIN: {
          phone: [{
            required: true,
            message: i18n.t("RuleMessage.InputAccount"),
          }],
          verifySmsCode: [{
            required: true,
            message: i18n.t("RuleMessage.InputPassword"),
          }],
        },
        MOBILE_LOGIN: {
          areaAndPhone: [{
            validator: this.validator_areaAndPhone,
            trigger: "submit",
          }],
          verifyImgCode: [{
            required: true,
            message: i18n.t("RuleMessage.InputVerifyCode"),
            trigger: "submit",
          }],
          verifySmsCode: [{
            required: true,
            message: i18n.t("RuleMessage.InputSmsCode"),
            trigger: "submit",
          }],
        },
        BIND_PHONE: {
          areaAndPhone: [{
            validator: this.validator_bindAreaAndPhone,
            trigger: "submit",
          }],
          verifySmsCode: [{
            required: true,
            message: i18n.t("RuleMessage.InputSmsCode"),
            trigger: "submit",
          }],
        },
      };
    },
    checkPasswordLength(rule, value, callback) {
      if (!value) {
        return callback(new Error(this.$t("RuleMessage.InputPassword")));
      }

      if (value.length < 8) {
        return callback(new Error(this.$t("RuleMessage.PasswordLengthError")));
      }

      if (value.length > 20) {
        return callback(new Error(this.$t("RuleMessage.PasswordLengthOver")));
      }

      return callback();
    },
    samePassword(rule, value, callback) {
      if (this.bindUser.newPassword !== value) {
        return callback(new Error(this.$t("RuleMessage.PasswordLengthNotEquals")));
      }

      return callback();
    },
    validator_bindAreaAndPhone(rule, value, callback) {
      if (!this.bindUser.area) {
        return callback(new Error(this.$t("RuleMessage.AreaNumberNotEquals")));
      }
      if (!this.bindUser.userMobile) {
        return callback(new Error(this.$t("TestKeyText.PleaseFillInMobileNumber")));
      }

      return callback();
    },
    validator_areaAndPhone(rule, value, callback) {
      if (!this.HeaderInfo.MobileLogin.area) {
        return callback(new Error(this.$t("RuleMessage.AreaNumberNotEquals")));
      }
      if (!this.HeaderInfo.MobileLogin.phone) {
        return callback(new Error(this.$t("TestKeyText.PleaseFillInMobileNumber")));
      }
      // todo: 校验手机号(看情况是否要加)
      if (false) {
        return callback(new Error(this.$t("RuleMessage.PhoneFormatError")));
      }
      return callback();
    },
  },
};
