var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.form ? 'el-form-item':'el-col',_vm._b({tag:"component",staticClass:"yun-form-item is-required",class:{
             'block-line':_vm.NOT_INLINE,
             'fill-content':_vm.fillContent,
             'readonly':_vm.readonly,
             'show-required':_vm.required,
             'label-right':_vm.align === 'right',
             'ofi-fs-en': _vm.isEn,
             'ofi-fs-ru': _vm.isRu,
           },style:(_vm.COMPUTED_STYLE),attrs:{"label":_vm.label,"label-width":_vm.labelWidth || null,"prop":_vm.prop}},'component',_vm.$attrs,false),[_vm._t("label",null,{"slot":"label"}),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }