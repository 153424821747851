import {SeaPortOptions, Cargonatures} from "@/api/business";
import pinyin                                                            from "pinyin";
import {getOrderDetail, getSharedOrderDetail}                            from "@/api/user";
import {getCityQueryList}                                                from "@/api/customer";
import {boxTypeOptions, boxSizeOptions}                                  from "@/api/options";
import {i18n}                                                            from "@/main";
import {log}                                                             from "lzutf8";

export default {
  namespaced: true,
  state: {
    portLHotStation: [],
    destHotStation: [],
    stations: [],

    orderResponse: {},

    seaStations: [],


    carrierList: {
      "A": [],
      "C": [],
    },
    cnts: [],
    cargonatures: []
  },
  mutations: {
    SET_CNTS(state, list) {
      state.cnts = list || [];
      if (!(state.cnts instanceof Array)) {
        state.cnts = [];
      }
    },
    SET_STATION(state, origin) {
      state.portLHotStation = origin.filter(x => x.CommonPortl === "T");
      state.destHotStation = origin.filter(x => x.CommonDest === "T");
      state.stations = origin;
    },
    SET_SEA_STATION(state, list) {
      state.seaStations = list;
    },
    SET_ORDER_RESPONSE(state, res) {
      state.orderResponse = res;
    },
    CLEAR_ORDER_RESPONSE(state) {
      state.orderResponse = null;
    },
    SET_CARGONATURES(state, list) {
      state.cargonatures = list
    }
  },
  actions: {
    async GET_BOXTYPE_INFO_QUERY({commit, rootState}, key = "") {

      const list = await boxTypeOptions(key);

      if (!list || list?.success === false) {
        return list?.message || i18n.t("RequestMessage.GetConListError");
      }

      commit("SET_CNTS", list);
    },
    async GET_SHARED_ORDER_INFO({commit}, id) {
      let res;
      try {
        res = await getSharedOrderDetail(id, "R");
      } catch (e) {

      }


      commit("SET_ORDER_RESPONSE", res.data);
    },
    async GET_ORDER_INFO({commit}, id) {
      const res = await getOrderDetail(id);
      commit("SET_ORDER_RESPONSE", res);
    },

    async GET_CARGONATURES({commit}) {
      const res = await Cargonatures()
      commit("SET_CARGONATURES", res);
    },

    async GET_SEA_STATION({commit, rootState}, filter = "") {

      const result = await SeaPortOptions(filter);

      if (!result.success || result?.msg) {
        return result?.message || i18n.t("RequestMessage.GetListError");
      }
      const list = result.data

      const locale = i18n.locale;
      let key = locale === "zh-CN" ? "fullFormate" : "fullFormate";
      if (locale === "zh-CN") {
        key = "fullFormate";
      } else if (locale === "en") {
        key = "fullFormate";
      }

      const defaultKey = {
        "zh-CN": "fullFormate"
      }[locale];

      commit("SET_SEA_STATION", list.map(x => ({
        ...x,
        PortName: x?.fullFormate?.trim() || x?.[defaultKey]?.trim() || x.fullFormate?.trim(),
        PortNameChs: x.fullFormate?.trim() || x.fullFormate?.trim(),
        name: x[key]?.trim() || x.fullFormate?.trim(),
        value: x.fullFormate,
        py: pinyin((x.fullFormate)?.trim() || x.fullFormate?.trim(), {
          style: pinyin.STYLE_NORMAL,
        }).join(""),
      })));
    },
  },
};
