<template>
  <el-select v-model="selectModel" @change="$emit('change',$event)" remote
             v-bind="$attrs" :loading="loading" :loading-text="$t('TestKey.Loading')"
             popper-class="__oms-extend__el-select-remote-extend"
             filterable
             :remote-method="onRemoteMethod">
    <el-option v-for="(item,index) in (options || [])" :value="item.value"
               :key="'item-remote-' + index"
               v-bind="item.$attrs" v-on="item.$listener">
      <!-- 高亮处理 -->
      <span class="fs-16 fc-777" v-html="item.label"></span>
    </el-option>
  </el-select>
</template>

<script>
// 下拉远程搜索
import request from "@/utils/request";
import model   from "@/mixins/model";

export default {
  name: "ElSelectRemoteExtend",
  mixins: [model],
  props: {
    options: {
      type: Array,
      default: [],
    },
    request: [String, Function],
    highlight: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      selectModel: "",
    };
  },
  methods: {
    splitKeywordAction(query, list) {
      return list.map(x => ({
        ...x,
        label: x.label.replace(query, `<em>${query}</em>`),
      }));
    },
    async onRemoteMethod(query) {
      let method = this.request;

      if (typeof method === "string") {
        method = () => {
          return request({
            url: method,
            method: "get",
          });
        };
      }

      this.loading = true;
      await delay(1000);
      const options = await method(query);
      this.$emit("update:options", this.highlight ? this.splitKeywordAction(query, options) : options);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.__oms-extend__el-select-remote-extend {

  em {
    color: #333;
  }
}
</style>