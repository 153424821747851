<template>
  <router-view/>
</template>

<script>

export default {
  name: 'WorkBenchLayout',
}
</script>

<style scoped>
.media-container-container {
  background-color: #f7f9fb;
}

.layout-container {
  margin: 32px 0 0 264px;
  max-width: 1624px;
  min-width: 1200px;
  min-height: 89vh;
}
</style>
