<template>
  <router-view/>
</template>

<script>
export default {
  name: "BaseLayout",
  beforeRouteEnter(to, from, next) {
    return next();
  },
};
</script>

<style scoped lang="scss">

</style>
