let __methods = [];

window.addEventListener("resize", () => {
  __methods.forEach(method => method?.());
});

export default {
  beforeDestroy() {
    __methods = __methods.filter(method => !this.__LOCAL_METHOODS?.includes?.(method));
  },
  methods: {
    ResizeCompose(...methods) {
      this.__LOCAL_METHOODS = methods;
      __methods = __methods.concat(methods.map(method => {
        if (method?.["execute"] === true) {
          method?.method?.();
          return method?.method;
        }

        return method;
      }));
    },
  },
};