<template>
  <el-input v-bind="$attrs" class="yun-input-extend"
            v-model="dynamic"
            :readonly="readonly"
            :disabled="yunFormItem.readonly || yunFormItem.disabled || disabled"
            :class="{'readonly':yunFormItem.readonly || readonly}">
    <slot name="suffix" slot="suffix"/>
    <slot name="prefix" slot="prefix"/>
    <slot name="append" slot="append"/>
  </el-input>
</template>

<script>
import {model} from "@/mixins";

export default {
  name: "YunInput",
  mixins: [model],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: Boolean,
  },
  inject: {
    yunFormItem: {
      default: "",
    },
  },
};
</script>

<style lang="scss">
.yun-input-extend {
  &.readonly {
    .el-input.is-disabled .el-input__inner {
      cursor: text;
      background-color: #fff;
      color: #333;
      border-color: #DCDFE6;
    }
  }

  &.el-input {
    display: flex;
  }
}
</style>
