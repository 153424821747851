import request, { scrmRequest } from '@/utils/request'
import { createCustomer, updateCustomer } from '@/api/customer'

export function countryCodeList(query) {
  return request({
    url: '/api/v1/countries',
    method: 'get',
    params: query,
    once: true,
    cache: {
      type: 'local',
      ttl: 24 * 3600
    }
  })
}

export function sendSmsCode(query) {
  return request({
    url: '/api/v1/sendsmscode',
    method: 'post',
    data: query,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
}

// 手机号登录
export function tryLoginByCode(phone, code, userid, openid) {
  const query = {
    phone,
    code,
  }
  if (userid) {
    query['userid'] = userid
  }
  if (openid) {
    query['openid'] = openid
  }
  return request({
    url: '/api/v1/tryloginbycode',
    method: 'post',
    data: query,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
}

// 账号密码登录
export function tryLogin(name, password) {
  const login = {
    name: name,
    password: password
  }
  return request({
    url: '/api/v1/accountlogin',
    method: 'post',
    data: login,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
}

// 微信登录
export function tryLoginByWechat(wxToken, param = {}) {
  if (!param['openid'] && param.hasOwnProperty('openid')) {
    delete param['openid']
  }
  if (!param['userid'] && param.hasOwnProperty('userid')) {
    delete param['userid']
  }
  return request({
    url: '/api/v1/wxtrylogin',
    method: 'post',
    data: {
      wxToken,
      ...param
    }
  })
}

// 获取个人信息
export function getInfo() {
  return request({
    url: '/api/v1/cususerinfo',
    method: 'get'
  })
}

// 退出登录
export function logout() {
  return request({
    url: '/api/v1/logout',
    method: 'post',
    data: {
      logout: 'true'
    }
  })
}

// 绑定手机号
export function bindPhone(query) {
  return request({
    url: '/api/v1/phonenum',
    method: 'post',
    data: query
  })
}

export function bindAccount(query) {
  return request({
    url: '/api/v1/accountuser',
    method: 'post',
    data: query,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
}

// 校验身份验证窗口的验证码
export function checkSmsCode(query) {
  return request({
    url: '/api/v1/checksmscode',
    method: 'post',
    data: query
  })
}

// 绑定企业信息（实质=更新客商资料）
export function bindCustomer(query) {
  return createCustomer(query)
}

//解绑微信
export function unbindWechat(query) {
  return request({
    url: 'api/v1/wechatuser',
    method: 'delete',
    params: query
  })
}

//绑定微信
export function bindWechat(query) {
  return request({
    url: 'api/v1/wechatuser',
    method: 'post',
    data: query
  })
}

// 修改密码
export function changePassword(oldPassword, password) {
  return request({
    url: '/api/v1/updatepassword',
    method: 'post',
    data: {
      oldpassword: oldPassword,
      password: password
    },
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
}

// 获取我的订舱申请
export function getOrderList(data) {
  return request({
    url: '/api/v1/order-list',
    method: 'post',
    data: {
      ...data
    },
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
}

// 获取我的运踪轨迹
export function getRailwayTrackingList(query, type = 'R') {
  const common = {
    filter: '',
    Atd: '',
    Ata: '',
    Portl: '',
    Portd: '',
    PortlCd: '',
    PortdCd: '',
    sort: 'asc',
    sortName: '',
    blFed: false,
    ...query
  }
  switch (type) {
    case 'R':
      return request({
        url: '/api/v1/railwaytracking-list',
        method: 'post',
        data: common
      })
    case 'S':
      return request({
        url: '/api/v1/seatracking-list',
        method: 'post',
        data: common
      })
    case 'A':
      return request({
        url: '/api/v1/airtracking-list',
        method: 'post',
        data: common
      })
  }
}

// 获取SCRM订单详情
export function getSharedOrderDetail(keyId, type = 'R') {
  switch (type) {
    case 'R':
      return request({
        url: '/api/v1/scrmorder_detail',
        method: 'get',
        params: {
          id: keyId,
          type
        }
      })
  }
}

export function getOrderDetail(keyId, type = 'R') {
  switch (type) {
    case 'R':
      return request({
        url: '/api/v1/railorderonline-detail',
        method: 'get',
        params: {
          keyId: keyId
        }
      })
    case 'S':
      return request({
        url: '/api/v1/orderonlineztyt-detail',
        method: 'get',
        params: {
          keyId: keyId
        }
      })
    case 'A':
      return request({
        url: '/api/v1/airorderonline-detail',
        method: 'get',
        params: {
          keyId: keyId
        }
      })
    case 'T':
      return request({
        url: '/api/v1/highwayorderonline-detail',
        method: 'get',
        params: {
          keyId: keyId
        }
      })
    case 'M':
      return request({
        url: '/api/v1/multitransorderonline-detail',
        method: 'get',
        params: {
          keyId: keyId
        }
      })
  }
}

// 获取订舱详情里的工作单列表
export function getWorkOrderDetail(keyId, type = 'R', { pageSize, pageIndex }) {
  switch (type) {
    case 'R':
      return request({
        url: '/api/v1/railorderonline-detailworklist',
        method: 'post',
        data: {
          keyId: keyId,
          pageIndex,
          pageSize
        }
      })
    case 'S':
      return request({
        url: '/api/v1/orderonlineztyt-detailworklist',
        method: 'post',
        data: {
          keyId: keyId,
          pageIndex,
          pageSize
        }
      })
    case 'A':
      return request({
        url: '/api/v1/airorderonline-detailworklist',
        method: 'post',
        data: {
          keyId: keyId,
          pageIndex,
          pageSize
        }
      })
    case 'T':
      return request({
        url: '/api/v1/highwayorderonline-detailworklist',
        method: 'post',
        data: {
          keyId: keyId,
          pageIndex,
          pageSize
        }
      })
    case 'M':
      return request({
        url: '/api/v1/multitransorderonline-detailworklist',
        method: 'post',
        data: {
          keyId: keyId,
          pageIndex,
          pageSize
        }
      })
  }
}

// 获取工作单列表
export function getWorkOrderList(type = 'R', data) {
  switch (type) {
    case 'R':
      return request({
        url: '/api/v1/railorderonline-worklist',
        method: 'post',
        data
      })
    case 'S':
      return request({
        url: '/api/v1/orderonlineztyt-worklist',
        method: 'post',
        data
      })
    case 'A':
      return request({
        url: '/api/v1/airorderonline-worklist',
        method: 'post',
        data
      })
    case 'T':
      return request({
        url: '/api/v1/highwayorderonline-worklist',
        method: 'post',
        data
      })
    case 'M':
      return request({
        url: '/api/v1/multitransorderonline-worklist',
        method: 'post',
        data
      })
  }
}

// 获取工作单详情
export function getWorkOrderListDetail(type = 'R', keyId, jobNoIn) {
  switch (type) {
    case 'R':
      return request({
        url: '/api/v1/railorderonline-worklistdetail',
        method: 'get',
        params: {
          keyId,
          jobNoIn
        }
      })
    case 'S':
      return request({
        url: '/api/v1/orderonlineztyt-worklistdetail',
        method: 'get',
        params: {
          keyId,
          jobNoIn
        }
      })
    case 'A':
      return request({
        url: '/api/v1/airorderonline-worklistdetail',
        method: 'get',
        params: {
          keyId,
          jobNoIn
        }
      })
    case 'T':
      return request({
        url: '/api/v1/highwayorderonline-worklistdetail',
        method: 'get',
        params: {
          keyId,
          jobNoIn
        }
      })
    case 'M':
      return request({
        url: '/api/v1/multitransorderonline-worklistdetail',
        method: 'get',
        params: {
          keyId,
          jobNoIn
        }
      })
  }
}

// 确认工作单已完成
export function confirmWorkOrderFinish(jobNoIn) {
  return request({
    url: 'api/v1/ordercompletesheet_cofirm',
    method: 'put',
    data: { jobNoIn }
  })
}

// 获取我的待办列表
export function getWorkOrderTodoList(data) {
  return request({
    url: '/api/v1/messagelist',
    method: 'post',
    data
  })
}

// 获取我的待办列表详情 标记已读
export function getWorkOrderTodoDetail(keyId) {
  return request({
    url: '/api/v1/message-detail',
    method: 'get',
    params: {
      keyId
    }
  })
}

// 获取工作台首页待办数目
export function getWorkOrderTodoCount() {
  return request({
    url: '/api/v1/homemessage',
    method: 'get'
  })
}

// 获取首页热门路线
export function getHotLineList(data) {
  return request({
    url: '/api/v1/hotlines1',
    method: 'post',
    data
  })
}

// 获取订舱 货物类型
export function getCargonature(params) {
  return request({
    url: 'api/v1/dict/cargonature',
    method: 'get',
    params,
    cache: {
      type: 'local',
      ttl: 24 * 3600
    }
  })
}

// 获取客户满意度评分
export function getCustomerScores(Id) {
  return request({
    url: '/api/v1/customerscores-query',
    method: 'get',
    params: {
      Id
    }
  })
}

// 获取客户满意度评分
export function CustomerScores(scores) {
  return request({
    url: '/api/v1/customerscores',
    method: 'put',
    data: scores
  })
}

// 获取客户满意度评分
export function CustomerScoresHash(scores) {
  return request({
    url: '/api/v1/customerscores-hash',
    method: 'put',
    data: scores
  })
}

// 获取首页客户满意度评分
export function getCustomerScoresNew() {
  return request({
    url: '/api/v1/customerscores-new',
    method: 'get'
  })
}

// 工作台获取账单
export function geiBillWorkPlace() {
  return request({
    url: '/api/v1/dunnew-top',
    method: 'get',
  })
}

// 获取我的账单列表
export function getMyBillList(data) {
  return request({
    url: '/api/v1/dunnew-list',
    method: 'post',
    data
  })
}
// 获取我的账单详情
export function getMyBillDetail(Id) {
  return request({
    url: '/api/v1/dunnew-detail',
    method: 'get',
    params: {
      Id
    }
  })
}

// 确认或者退回账单
export function confirmBill(data) {
  return request({
    url: '/api/v1/dunnew-update',
    method: 'put',
    data
  })
}

// 获取账单-下载
export function getBillDownload(params) {
  return request({
    url: '/api/v1/dunnew_print',
    method: 'get',
    params,
    // responseType: 'blob'
  })
}
// 下载账单
export function downloadBillFile(data) {
  return request({
    url: '/api/v1/downloadfile',
    method: 'post',
    data,
    responseType: 'blob'
  })
}
// 下载账单
export function uploadBillFile(params,data) {
  return request({
    url: '/api/v1/uploadfile',
    method: 'post',
    params,
    data
  })
}


// 获取开具发票列表
export function getInvoiceList(data) {
  // return request({
  //   url: '/api/v1/dunnew-list',
  //   method: 'post',
  //   data
  // })
}

// 获取开票记录列表
export function getInvoiceRecordList(data) {
  // return request({
  //   url: '/api/v1/dunnew-list',
  //   method: 'post',
  //   data
  // })
}

// 我的订阅
export function subscribe(data) {
  return request({
    url: '/api/v1/addsubscribe',
    method: 'post',
    data: {
      ...data
    },
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
}

// 我的订阅列表
export function getMySubscribeList(data) {
  return request({
    url: '/api/v1/mysubscribe',
    method: 'post',
    data: {
      ...data
    },
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
}

// 获取开具发票列表
export function deleteSubscribe(id) {
  return request({
    url: '/api/v1/delsubscribe/'+id,
    method: 'get',
    once: true,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
}

