<template>
  <div style="font-size:100%;padding:8px;">
    <h1>Not Found</h1>
    <p v-if="URL">The requested URL {{ URL }} was not found on this server.</p>
    <p v-else>The requested was not found on this server.</p>
  </div>
</template>

<script>
export default {
  name: "Page404",
  computed: {
    URL() {
      return decodeURIComponent(this.$route.query?.url || '');
    },
  },
};
</script>

<style scoped>
h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
</style>