export function bindWatch(...rest) {
  let watch = {};
  for (let [variable, prop, deep = false] of rest) {
    if (deep) {
      watch[variable] = {
        deep: true,
        handler() {
          this.$emit(`update:${prop}`, this[variable]);
        },
      };
      watch[prop] = {
        deep: true,
        handler() {
          this[variable] = this[prop];
        },
      };
    } else {
      watch[variable] = function () {
        this.$emit(`update:${prop}`, this[variable]);
      };
      watch[prop] = function () {
        this[variable] = this[prop];
      };
    }
  }

  return {
    watch,
  };
}

export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: [
        String,
        Number,
        Object,
        Boolean,
        Array,
      ],
      default: undefined,
    },
  },
  data() {
    return {
      dynamic: this.value,
    };
  },
  watch: {
    dynamic(val) {
      this.$emit("change", val);
    },
    value(val) {
      this.dynamic = val;
    },
  },
};
