import Axios             from "axios";
import Vue               from "vue";
import {getToken}        from "@/store/modules/user";
import store             from "@/store";
import * as BufferModule from "buffer";
import ls                from "localstorage-slim";
import qs                from "qs";
import router            from "@/router";
import {messageWarning}  from "@/mixins/notify";
import {i18n}            from "@/main";

// const {Buffer} = BufferModule;
const baseRequest = Axios.create({
  baseURL: process.env.VUE_APP_BASE_API || "/f9api",
  timeout: 500000,
  withCredentials: true,
});

export function toQueryParams(params, data) {
  params && Object.keys(params).forEach(key => {
    if (params[key] instanceof Array) {
      params[key] = JSON.stringify(params[key]);
    }
  });
  data && Object.keys(data).forEach(key => {
    if (data[key] instanceof Array) {
      data[key] = JSON.stringify(data[key]);
    } else if (typeof data[key] === "object" && data[key] !== null && data[key] !== undefined) {
      data[key] = JSON.stringify(data[key]);
    }
  });
  params = formatQuery(params);
  params.customer = true;

  if (store.getters.token?.role === "admin") {
    params && (params.customer = false);
  }

  params = {
    hash: hashRequest(params, data),
    ...params,
  };

  return params;
}

// todo: 可尝试将所有API返回的数据做统一转换， 提供一项默认转换，以及可自定义转换

/** 请求前拦截 **/
[baseRequest].forEach(request => request.interceptors.request.use(config => {
  config.params = toQueryParams(config.params, config.data);
  // config.params.customer = true;

  if (!config.headers["Content-Type"]) {
    config.headers["Content-Type"] = "application/x-www-form-urlencoded;charset=utf-8";
    config.transformRequest = [data => qs.stringify(data)];
  }

  return config;
}));

/** 请求后返回前拦截 **/
[baseRequest].forEach(request => request.interceptors.response.use(async response => {
  const config = response.config;
  const cache = config.cache;

  if (cache?.type === "local") {
    const {type, ...options} = cache;
    if (response.data?.success !== false) {
      ls.set(config.url, response.data, options);
    }
  }

  const data = response.data;
  //只做一次提示即可
  if (data?.success === false && data?.msg.indexOf('登录过期，请重新登录') >= 0 && getToken()) {
    await store.dispatch("user/LOG_OUT", "local");
    messageWarning(i18n.t("RequestMessage.HintReLoginMessage"));
    router.replace({
      name: "Home",
    });
  } else if (data?.success === false && data?.msg.indexOf('token不可为空') >= 0) {
    await store.dispatch("user/LOG_OUT", "local");
    messageWarning(i18n.t("RequestMessage.HintReLoginMessage"));
    router.replace({
      name: "Home",
    });
  }
  return data;
}, error => {
  console.error(error);
  //todo: 做个全局提示
}));

class RequestManage {
  stack = [];
  map = new Map();

  push(request) {
    this.stack.push(request);
    request.finally(() => {
      const index = this.stack.indexOf(request);
      this.stack.splice(index, 1);
    });
  }

  mapSet(key, request) {
    this.map.set(key, request);

    request.finally(() => {
      try {
        this.map.delete(key);
      } catch (e) {
        console.log(e)
      }
    });
  }

  mapGet(key) {
    return this.map.get(key);
  }

  remove(key) {
    return this.map.delete(key);
  }

  clear(message = i18n.t("RequestMessage.AbortMessage")) {
    this.stack.forEach(request => {
      try {
        request.cancel(new Error(message));
      } catch (e) {

      }
    });
  }
}

export const requestManage = new RequestManage();

function createRequest(requestFactory) {
  return function (config, ...args) {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    const code = (config.params ? JSON.stringify(config.params) : "non-data") + (config.data ? JSON.stringify(config.data) : "non-data");

    config.cancelToken = source.token;

    if (config.cache?.type === "local" && !(process.env.NODE_ENV === "development")) {
      const data = ls.get(config.url + code);
      if (data && data?.success !== false) {
        return data;
      }
    }

    if (config?.once === true) {
      // console.log(requestManage.mapGet(config.url));
      if (requestManage.mapGet(config.url + code)) {
        const request = requestManage.mapGet(config.url + code);
        request.finally(() => {
          requestManage.remove(config.url + code);
        });
        return request;
      }
    }

    const requestSlot = requestFactory(config, ...args);

    requestSlot.cancel = source.cancel;
    requestManage.push(requestSlot);
    requestManage.mapSet(config.url, requestSlot);
    requestManage.mapSet(config.url + code, requestSlot);


    //有效请求取决于 最后一次 或是 第一次，默认最后一次
    // const isLast = config.once === true || config.once === "last";
    // if (config?.once === true) {
    //   requestManage.push(requestSlot);
    // }


    // source.cancel("我取消请求了！");

    // requestSlot.cancel("我取消请求了！");

    return requestSlot;
  };
}

const request = createRequest(baseRequest);
export default request;

Vue.prototype.$request = request;

function formatQuery(params) {
  const obj = params || {};
  const moment = require("moment-timezone");
  const token = getToken();

  for (const index in obj) {
    if (obj[index] === null) {
      obj[index] = "";
    }
  }
  if (token) {
    obj["token"] = token;
  }
  obj["companyid"] = 0;
  obj["timestamp"] = moment().tz("Asia/Shanghai").format("YYYY-MM-DD HH:mm:ss");
  return obj;
}

function toQueryString(items) {
  const qs = [];
  items.forEach((item) => {
    qs.push(`${item.name}=${item.value}`);
  });
  return qs.join("&");
}

function sortAscending(items) {
  items.sort((item1, item2) => {
    if (item1.name > item2.name) {
      return 1;
    }
    if (item1.name < item2.name) {
      return -1;
    }
    return 0;
  });
  return items;
}

function parseData(obj) {
  const items = [];
  if (obj) {
    Object.keys(obj).forEach((key) => {

      if (![undefined, null].includes(obj[key])) {
        items.push({
          name: key.toLowerCase(),
          value: obj[key],
        });
      }
    });
  }
  return items;
}

export function hashRequest(params, data) {
  //const publickey = "7D0BBA28-E239-4B5E-B7F2-EFD5537E9B8A";
  const queryItems = parseData(params);
  const formItems = parseData(data);
  const clonedQueryItems = JSON.parse(JSON.stringify(queryItems));
  const orderedQueryItems = sortAscending(clonedQueryItems);
  const clonedFormItems = JSON.parse(JSON.stringify(formItems));
  const orderedFormItems = sortAscending(clonedFormItems);
  const originalParts = [
    toQueryString(orderedQueryItems),
    toQueryString(orderedFormItems),
   // publickey,
  ];
  let originalContent;
  if (data && data.constructor.name === "FormData") {
    originalContent = originalParts.join("||");
  }
  else if (data && data.constructor.name === "Object" && JSON.stringify(data) === "{}") {
    originalContent = originalParts.join("||");
  }
  else {
    originalContent = originalParts.filter(x => !!x).join("||");
  }
  // debugger
  return computeHash(originalContent);
}

function computeHash(content) {
  const sha1 = require("sha1");
  const hashBytes = sha1(content, {asBytes: true});
  const hashBuf = new BufferModule.Buffer(hashBytes);
  return hashBuf.toString("base64");
}
