<template>
<span v-loading="loading"
      v-bind="$attrs"
      class="loading-default-style transition"
      element-loading-spinner="el-icon-loading" :class="{min:loading}">
  <slot/>
</span>
</template>

<script>
export default {
  name: "LoadingSpan",
  props: {
    loading: Boolean,
  },
};
</script>

<style scoped>

</style>