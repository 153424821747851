<template>
  <router-view/>
</template>

<script>
import store                          from "@/store";
import {messageWarning, modalWarning} from "@/mixins/notify";
import NProgress                      from "nprogress";
import {i18n}                         from "@/app";

export default {
  name: "OrderLayout",
  async beforeRouteEnter(to, from, next) {
    if (!store.getters.isLogin) {
      const langType = window.localStorage.getItem("lang");
      if (langType) {
        await store.dispatch("lang/ASYNC_LOAD_LANG_JSON", langType);
      }

      messageWarning('温馨提示:请您先进行登录后再查看相关的线路服务信息');
      NProgress.done();
      // await modalWarning("提示", "请先进行登录", {
      //   confirmButtonText: "回到首页",
      // });
      return next({
        name: "Home",
      });
    }
    next();
  },
};
</script>

<style scoped>

</style>
