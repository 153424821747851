const TableSpecificationsEnum = {
  PreDefine_BookingSeaRequest: "BookingSeaRequestField",
  PreDefine_FreightSeaTransport: "FreightSeaTransport",
  PreDefine_BoxContainerInfo: "BoxContainerInfo",
  PreDefine_BookingShipCargoFCLWorkOrder:'BookingShipCargoFCLWorkOrder',
  PreDefine_BookingShipCargoLCLWorkOrder:'BookingShipCargoLCLWorkOrder',
  PreDefine_ShipFCLWorkOrder:'ShipFCLWorkOrder',
  PreDefine_ShipLCLWorkOrder:'ShipLCLWorkOrder',
  PreDefine_ShipWorkOrderDetailPackingList:'ShipWorkOrderDetailPackingList',
  PreDefine_WorkOrderTodoList:'WorkOrderTodoList',
  PreDefine_CollectList:'CollectList',

  PreDefine_BillList:'BillList',
  PreDefine_BillDetailList:'BillDetailList',
  PreDefine_InvoiceList:'InvoiceList',
  PreDefine_InvoiceDetailList:'InvoiceDetailList',
  PreDefine_InvoiceRecordList:'InvoiceRecordList',

  
  /*订单号*/      OrderNo: "shporderno",
  /*订单号*/      BillOrderNo: "OrderNo",
  /*订单号*/      TrackOrderNo: "Track.BookingNo",
  /*订单号 加宽*/ OrderNoWidth: "workOrder.BookingNo",
  /*订单号 加宽*/ AirOrderNoWidth: "AirWorkOrder.BookingNo",
  /*委托号*/      EntrustNo: "ProxyNo",
  /*始发地*/      PlaceOfOrigin: "Booking.PortlDisplay",
  /*目的地*/      Destination: "Booking.PortdDisplay",
  /*装货港*/      SeaPlaceOfOrigin: "Sea.PortlDisplay",
  /*卸货港*/      SeaDestination: "Sea.PortdDisplay",
  /*装货港*/      SeaPlaceOfOrigin2: "shippingService.pol",
  /*卸货港*/      SeaDestination2: "shippingService.pod",
  /*箱型箱量*/     BoxTypeAndVolume: "conStat",
  /*进出口*/       IOM: "IeId",
  /*用箱方式*/     BoxMode: "goodslist.usingtype",
  /*装箱方式*/     PackingMethod: "LclId",
  /*货物品名*/     Commodity: "brand",
  /*货物品名(v2) */CargoName: "Freight.CargoName",
  /*预计发车日期*/  ETD: "Etsh",
  /*ETD*/         EngETD: "etd",
  /*船期*/         SeaEtsh: "etd",
  /*订单状态*/     OrderStatus: "status",
  /*下单时间*/     ApplicationDate: "Order.CreateTime",
  /*运踪状态*/      BookingTrackStatus: "TrackText",

  /*工作号*/       JobNumber: "JobNoIn",
  /*工作号 加宽*/   JobNumberWidth: "width.JobNoIn",
  /*工作号 加宽*/   AirJobNumberWidth: "JobnoIn",
  /*账单 工作号*/   BillJobNumber: "Bill.Jobnoin",
  /*箱号*/         ContainerNo: "ConNo",
  /*箱号*/         BillContainerNo: "Bill.Conno",
  /*始发站*/        DepartureStation: "Freight.Portl",
  /*目的站*/        DestinationStation: "Freight.Portd",
  /*始发站 加宽*/   DepartureStationWidth: "FreightWidth.Portl",
  /*目的站 加宽*/   DestinationStationWidth: "FreightWidth.Portd",
  /*起运站*/        StartStation: "Start.Portl",
  /*起运站*/        RailwayStartStation: "Start.Placer",
  /*发车日期*/      DepartureDate: "Freight.Etsh",
  /*跟踪状态*/      TrackStatus: "Freight.Status",

  /*箱型*/         BoxType: "SizeType",
  /*封签号*/        SealNo: "SealNo",
  /*件数/箱*/       PiecesBox: "Ctns",
  /*件数/单位*/     PiecesUnit: "unit.Ctns",
  /*毛重/KGS*/     GrossWeight: "Weight",
  /*体积/CBM*/     Volume: "Meas",
  /*运单号*/        WaybillNo: "Blno",
  /*箱皮重/KGS*/    TareWeight: "ConWeight",
  /*加固重量/KGS*/   ReinforcementWeight: "FixWeight",
  /*VGM/KGS*/       VGM: "VGMWeight",

  /*航司*/         AirManager: "Air.Manager",

  /*提单号*/       SeaWaybillNo: "Sea.Blno",
 
  /*预计发车时间*/       StartTime: "Etsh",
  /*预计到站时间*/       EndTime: "Etad",
  /*实际发车时间*/       ActDepartureTime: "Atd",
  /*实际到站时间*/       ActArrivalTime: "Ata", 
  /*订单交付时间*/       orderDeliveryTime: "OrderDt",
  /*订舱箱量*/       BookingVolume: "ConStat",
  /*状态*/        State: "State", // 暂定的字段
  /*状态*/        FixedState: "fixed.State", // 暂定的字段
  // /*状态*/        fdState: "fixedState.State", // 暂定的字段
  /*国内车型车量*/       DomesticVehicleVolume: "CatTypeIn",
  /*国外车型车量*/       ForeignVehicleVolume: "CatTypeOut",
  /*起运地*/       startPortl: "Departure.Portl",
  /*目的地*/       startPortd: "Departure.Portd",

  /*装货港*/      BookingPlaceOfOrigin: "Booking.Portl",
  /*卸货港*/      BookingDestination: "Booking.Portd",
  /*装货港*/      ShipPlaceOfOriginWidth: "ShipWorkOrder.Portl",
  /*起运港*/      ShipStartWidth: "ShipStart.Portl",
  /*卸货港*/      ShipDestinationWidth: "ShipWorkOrder.Portd",
  /*总件数*/      Total: "SumCtns",
  /*总毛重*/      TotalGrossWeight: "SumWeight",
  /*总体积*/      TotalVolume: "SumMeas",
  /*订舱号*/      Sono: "Sono",
  /*序号*/        SerialNo: "Index",
  /*序号 宽度*/    SerialNoWidth: "width.Index",
  /*车号*/        VehicleNo: "TruckNo",
  /*司机*/        Driver: "Driver",
  /*司机电话*/        DriverPhone: "DriverTel",
  /*品名描述*/        ProductDesc: "CargoName",
  /*拖车公司*/        CarTowCompany: "Trailer",
  /*装货时间*/        LoadingTime: "LoadDate",
  /*详细地址*/        Address: "LoadPlaceDoc",
  
  /*费用名称*/        FeeName: "CostName",
  /*账单 费用名称*/        BillFeeName: "Bill.CostName",
  /*关系人*/        RelatedPerson: "WhoName",
  /*币别*/         Currency: "Currcd",
  /*币别*/         InvoiceCurrency: "OrinCurcd",
  /*汇率*/          ExchangeRate: "ChgRate",
  /*计费单位*/       UnitOfCharging: "UnitCd",
  /*数量*/         Count: "Quantity",
  /*单价*/         UnitPrice: "Rate",
  /*总金额*/        LumpSum: "Amount",
  /*发生日期*/        dateOfOccurrence: "RecordDate",
  /*已付金额*/        AmountPaid: "Ysje",
  /*未付金额*/        OutstandingAmount: "Whje",
  /*录入时间*/        EntryTime: "createTime",
  /*录入时间*/        BillEntryTime: "Bill.CreateTime",
  /*账单时间*/        BillTime: "Bill.invoice.CreateTime",
  /*备注*/        Remarks: "Remark",
  /*待办事项*/        TodoList: "TodoList",
  /*发送时间*/        SendTime: "SendDate",

  /*账单号*/         AccountNo: "DunNo",
  /*账单号*/         AccountNoPopover: "Popover.DunNo",
  /*应付总金额*/      TotalPayable: "OrinAmount",
  /*应付金额*/      PayableCount: "Whje",
  /*对账人*/        Reconciler: "CusAbbrname",
  /*预计应付日期*/     PayableDate: "EsReceiveDate",
  /*账单状态*/        BillState: "OmsState",
  /*付款状态*/        PaymentState: "state",
  /*创建时间*/        BillCreateTime: "bill.CreateTime",
  /*客户名称*/        CustonmName: "WhoName",
  /*客户名称*/        InvoiceCustonmName: "Invoice.CusAbbrname",
  /*录入人*/        EntryPerson: "Creator",


  /*收藏线路信息*/        CollectList: "collect",

  /*开票主体*/        BillSubject: "invoice.InvoHeader",
  /*可开票金额*/        BillableAmount: "Invoice.OrinAmount",
  /*发票状态*/        InvoiceStatus: "InvoState",
  /*发票号*/        InvoiceNo: "InvoNo",
  /*发票抬头*/        Invoice: "InvoHeader",
  /*发票总额*/        TotalInvoice: "Record.Amount",
  /*发票性质*/        InvoiceNature: "InvoiceNature",
  /*申请时间*/     ApplicationTime: "Invoice.CreateTime",

  /*收藏航线信息*/     CollectLineInfo: "Collect.Info",
};

export default TableSpecificationsEnum;
