var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.form ? 'el-form' : 'el-row',_vm._b({ref:"FORM_ROW",tag:"component",staticClass:"yun-row",class:{
             'width-auto':_vm.autoWidth,
             'clear-both':_vm.clearBoth,
             'readonly':_vm.readonly,
             'disabled':_vm.disabled,
             'yun-row-en':_vm.$i18n.locale === 'en',
             'yun-row-ru':_vm.$i18n.locale === 'ru',
             'yun-row-zh':_vm.$i18n.locale === 'zh-CN',
             'yun-row-tw':_vm.$i18n.locale === 'zh-TW',
           },attrs:{"labelWidth":_vm.inline ? _vm.labelWidth : null,"inline":_vm.inline,"disabled":_vm.disabled || _vm.readonly,"model":_vm.model,"rules":_vm.rules}},'component',_vm.$attrs,false),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }