import App                from "@/app.vue";
import Vue                from "vue";
import AppLink            from "@/components/AppLink";
import VueI18n            from "vue-i18n";
import store              from "@/store";
import router             from "@/router";
import ElementUI          from "element-ui";
import ElCheckboxExtend from "@/components/elementExtend/ElCheckboxExtend";
import ElRadioExtend from "@/components/elementExtend/ElRadioExtend";
import ElSelectExtend from "@/components/elementExtend/ElSelectExtend";
import ElSelectRemoteExtend from "@/components/elementExtend/ElSelectRemoteExtend";
import ElTagInput from "@/components/elementExtend/ElTagInput";
//import * as ElementExtend from "@/components/elementExtend/ElCheckboxExtend";
import YunFormItem  from "@/components/container/YunFormItem";
import YunRow  from "@/components/container/YunRow";
import LoadingSpan        from "@/components/LoadingSpan";
import YunDialogExtend    from "@/components/yunExtend/YunDialogExtend";
import YunInput           from "@/components/yunExtend/YunInput";
import YunSelect          from "@/components/yunExtend/YunSelect";
import YunDatePicker          from "@/components/yunExtend/YunDatePicker";
import YunPagination      from "@/components/yunExtend/YunPagination";
import apiMixin           from "@/mixins/api.method";

// import "animate.css"
// import "wowjs/css/libs/animate.css"
// import wow from 'wowjs'

import "@/assets/fonts/fonts.css";
import "@/directives/el-drag-dialog";
import "@/router/modules/permission";
import "@/directives/flex";
import common from '@/utils/common'
import "@/mixins";
import "@/api";
import "@/filter/index";
import dependent       from "@/mixins/dependent";
import VueAnimateNumber from 'vue-animate-number'; //数字滚动组件
import scrollReveal from "scrollreveal";
import 'element-ui/lib/theme-chalk/index.css';

Vue.prototype.$scrollReveal = scrollReveal();

const vueversion = "2";

Vue.use(VueI18n);
Vue.use(ElementUI);
Vue.use(VueAnimateNumber);

Vue.mixin(apiMixin);

Vue.component("app-link", AppLink);
Vue.component("yun-row", YunRow);
Vue.component("yun-child", YunRow);
Vue.component("yun-form-item", YunFormItem);
Vue.component("yun-input", YunInput);
Vue.component("yun-select", YunSelect);
Vue.component("yun-date-picker", YunDatePicker);
Vue.component("yun-pagination", YunPagination);
Vue.component("el-checkbox-extend", ElCheckboxExtend);
Vue.component("el-radio-extend", ElRadioExtend);
Vue.component("el-select-extend", ElSelectExtend);
Vue.component("el-select-remote-extend", ElSelectRemoteExtend);
Vue.component("el-tag-input", ElTagInput);

Vue.component("loading-span", LoadingSpan);
Vue.component("yun-dialog", YunDialogExtend);

export const i18n = new VueI18n({
  locale: "zh-CN",
  messages: {
    zh:require('@/lang/zh-CN.json')
  },
  silentTranslationWarn: true, //解决vue-i18n黄色警告"value of key 'xxx' is not a string"和"cannot translate the value of keypath 'xxx'.use the value of keypath as default",可忽略
    globalInjection: true, // 全局注入
    fallbackLocale: 'zh', // 指定的locale没有找到对应的资源或当前语种不存在时，默认设置当前语种为中文
});


// Object.keys(key => {
// });
let base = VueI18n.prototype.mergeLocaleMessage;
VueI18n.prototype.mergeLocaleMessage = function (locale, localeMessageObject) {
  base.call(this, locale, localeMessageObject);
  this.mergeLocalFlattenMessage(locale, window.flattenObject(localeMessageObject));
};
VueI18n.prototype.mergeLocalFlattenMessage = function (locale, localeFlattenMessageObject) {
  if (!this._messageFlatten) {
    this._messageFlatten = {};
  }

  if (!this._messageFlatten[locale]) {
    this._messageFlatten[locale] = {};
  }
  Object.assign(this._messageFlatten[locale], localeFlattenMessageObject);
};

let labelList = {};
Vue.prototype.$fKey = (label, startsWith) => {
  let key = labelList[label];

  if (!i18n._messageFlatten) return "";

  if (!key) {
    let flatten = i18n._messageFlatten["zh-CN"];
    key = Object.keys(flatten).find(key => (!startsWith || key.startsWith(startsWith)) && flatten[key] === label);

    labelList[label] = key;
  }

  if (!key) {
    return label;
  }

  return key;
};

Vue.mixin({
  methods: {
    /** @param label 文本
     * @params messageParams | List 参数列表*/
    $t_reserve(label, ...messageParams) {
      let key = this.$fKey(label);
      return this.$t(key, messageParams);
    }
  }
});

const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount("#app");

export default app;