import Vue from 'vue'
import moment from 'moment-timezone'

Vue.filter('displayDate', (value) => {
  if (!value) return value
  const mom = moment(value)
  if (mom.isValid()) {
    return moment(value).format('YYYY-MM-DD')
  }

  return value
})
Vue.filter('valueFilter', (val) => {
  if (val !== 0) {
    return val === '' ? '--' : val ?? '--'
  } else {
    return val
  }
})

