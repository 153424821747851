import {getCartType, getServiceTypeOptions,getClerkOptions} from "@/api/options";

export default {
  props: {
    api: [String, Function],
    apiCallback: Function,
    apiParams: Object,
  },

  computed: {
    API() {
      return {
        Options: {
          getCartType,
          getServiceTypeOptions,
          getClerkOptions
        }
      };
    }
  },
};
