<template>
  <!--  <el-config-provider :locale="zhCn">-->
  <virtual-scrollbar>
    <router-view />
  </virtual-scrollbar>
</template>
<script>
import { v4 as guid } from 'uuid'
import VirtualScrollbar from '@/components/VirualScrollbar'
import _ from 'lodash'
 
// 解决ERROR ResizeObserver loop completed with undelivered notifications. 问题
const _ResizeObserver = window.ResizeObserver

window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = _.debounce(callback, 16)

    super(callback)
  }
}
// import {useStore}         from "vuex";
// import {ElConfigProvider} from "element-plus";
// import zhCn from "element-plus/lib/locale/lang/zh-cn";

// const store = useStore();
// store.dispatch("INITIALIZE_LANGUAGES", "zh-CN");
export default {
  components: { VirtualScrollbar },
  created() {
    //this.$store.dispatch('lang/INITIALIZE_LANGUAGES', window.localStorage.getItem('lang'))

    if (!window.localStorage.getItem('BROWSER_IDENTIFY_UUID')) {
      window.localStorage.setItem('BROWSER_IDENTIFY_UUID', guid())
    }
  }
}
</script>
<style lang="scss">
@import "./styles/common";
</style>
