import Vue               from "vue";
import Vuex              from "vuex";
//import lang              from "./modules/lang";
import user              from "./modules/user";
import business          from "./modules/business";
import pinyin            from "pinyin";
import {countryCodeList} from "@/api/user";
import {
  getCityQueryList,
  getCountryQueryList,
}                        from "@/api/customer";
import {getFileCategory, boxTypeOptions, boxSizeOptions} from "@/api/options";
import ls                from "localstorage-slim";
import {i18n}            from "@/main";

Vue.use(Vuex);

// 公共数据丢在index.js
export default new Vuex.Store({
  state: {
    //区号
    countryTelCodeList: [],
    // 国家
    countryList: [],
    // 城市
    cityList: [],

    // 文件分类
    fileCategoryOptions: [],
    // dialogHome: true,
    // dialogWork: true
  },
  getters: {
    locale: state => state.lang.locale,
    token: state => state.user.token,
    user: state => state.user.info || {},
    isLogin: state => !!state.user?.token,
    cnts: state => state.business.cnts.map(x => x.Size + x.Type),
    area: state => ({
      stations: state.business.stations,
      portl: state.business.portLHotStation,
      dest: state.business.destHotStation,
      seaStations: state.business.seaStations,
    }),
  },
  mutations: {
    SET_COUNTRY_TEL_CODE_LIST(state, list) {
      state.countryTelCodeList = list;
    },
    SET_CITY_QUERY_LIST(state, list) {
      state.cityList = list;
    },
    SET_COUNTRY_QUERY_LIST(state, list) {
      state.countryList = list;
    },
    SET_FILE_CATEGORY(state, list) {
      state.fileCategoryOptions = list;
      console.log(list,'list');
    },
    // DIALOG_HOME(state) {
    //   state.dialogHome = false
    // },
    // DIALOG_WORK(state) {
    //   state.dialogWork = false
    // }
  },
  actions: {
    async GET_BOXTYPE_INFO_QUERY({commit, rootState}, key = "") {
      // if (rootState.business.cnts.length > 0) {
      //   return "";
      // }

      const list = await boxTypeOptions(key);
      // console.log(list,'多式联运数据');
      if (!list || list?.success === false) {
        return list?.message || i18n.t("RequestMessage.GetConListError");
      }

      commit("SET_BOXTYPE", list);
    },
    async GET_BOXSIZE_INFO_QUERY({commit, rootState}, key = "") {
      // if (rootState.business.cnts.length > 0) {
      //   return "";
      // }

      const list = await boxSizeOptions(key);
      if (!list || list?.success === false) {
        return list?.message || i18n.t("RequestMessage.GetConListError");
      }

      commit("SET_BOXSIZE", list);
    },
    async GET_FILE_CATEGORY({commit}) {
      const res = await getFileCategory();

      if (!res || res?.message) {
        return res?.message || i18n.t("RequestMessage.GetFileCategoryFail");
      }

      commit("SET_FILE_CATEGORY", res);
    },
    //获取城市
    async GET_CITY_QUERY_LIST({commit}) {
      const res = await getCityQueryList();

      const key = {
        "zh-CN": "citynamechs",
        "zh-TW": "citynamecht",
        "en": "citynameeng",
        "ru": "citynamerus",
      }[i18n.locale];
      const defaultKey = {
        "zh-CN": "citynamechs",
        "zh-TW": "citynamechs",
        "en": "citynameeng",
        "ru": "citynameeng",
      }[i18n.locale];

      commit("SET_CITY_QUERY_LIST", res.data.map(x => ({
        ...x,
        PortNameChs: x.citynamechs?.trim() || "",
        PortNameCht: x.citynamecht?.trim() || "",
        PortNameEng: x.citynameeng?.trim() || "",
        PortNameRus: x.citynamerus?.trim() || "",
        PortName: x.CityName?.trim() || "",
        name: (x[key] || x[defaultKey] || x.cityname)?.trim() || "",
        value: x.citycd,
        py: pinyin(x.cityname, {
          style: pinyin.STYLE_NORMAL,
        }).join(""),
      })));
    },
    // 获取国家
    async GET_COUNTRY_QUERY_LIST({commit, state}) {
      const res = await getCountryQueryList();

      const key = {
        "zh-CN": "countrynamechs",
        "zh-TW": "countrynamecht",
        "en": "countrynameeng",
        "ru": "countrynamerus",
      }[i18n.locale];
      const defaultKey = {
        "zh-CN": "countrynamechs",
        "zh-TW": "countrynamechs",
        "en": "countrynameeng",
        "ru": "countrynameeng",
      }[i18n.locale];
      commit("SET_COUNTRY_QUERY_LIST", res.data.map(x => ({
        ...x,
        PortNameChs: x.countrynamechs?.trim() || "",
        PortNameCht: x.countrynamecht?.trim() || "",
        PortNameEng: x.countrynameeng?.trim() || "",
        PortNameRus: x.countrynamerus?.trim() || "",

        PortName: (x.countryname)?.trim() || "",
        name: (x[key] || x[defaultKey] || x.countryname)?.trim() || "",
        value: x.countrycd,
        py: pinyin(x.countrynamechs, {
          style: pinyin.STYLE_NORMAL,
        }).join(""),
      })));
    },
    // 获取区号
    async SET_COUNTRY_TEL_CODE_LIST({commit, state}) {
      let list = await countryCodeList();
      list = list.sort((x, y) => pinyin.compare(x.countrynamechs, y.countrynamechs)).filter(x => !!x["countrytelcode"]);
      if (list.length <= 0) {
        ls.remove("/b/country/country-list");
      }
      commit("SET_COUNTRY_TEL_CODE_LIST", list.map(x => ({
        ...x,
        PortNameChs: x.countrynamechs?.trim() || "",
        PortNameEng: x.countrynameen?.trim() || "",
        PortName: (x.countryname)?.trim() || "",

        py: pinyin(x.CountryNameChs, {
          style: pinyin.STYLE_NORMAL,
        }).join(""),
        name: x.countrynamechs,
        value: x.countrytelcode,
      })));
    },
  },
  modules: {
    //lang,
    user,
    business,
  },
});
