<template>
  <el-tooltip :content="hintText" :disabled="!hint" placement="top">
    <el-checkbox v-model="dynamic" :label="label" :true-label="trueLabel" :false-label="falseLabel" class="oms-checkbox"
                 :disabled="readonly || disabled"
                 :class="{'readonly':readonly}"
                 v-bind="$attrs" v-on="$listeners">
      <slot/>
    </el-checkbox>
  </el-tooltip>
</template>

<script>
import {model} from "@/mixins";

export default {
  name: "ElCheckboxExtend",
  mixins: [model],
  props: {
    label: [String, Number],
    trueLabel: [String, Number],
    falseLabel: [String, Number],
    readonly: Boolean,
    disabled: Boolean,
    hint: Boolean,
    hintText: String,
  },
};
</script>

<style lang="scss">
.oms-checkbox {
  &.el-checkbox {
    .el-checkbox__input {
      display: none;
    }

    .el-checkbox__label {
      padding: 0 30px;
      border: 1px solid #aaa;
      transition: .2s linear;
      font-size: 16px;
      border-radius: 5px;
      height: 40px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
    }

    &.is-checked {
      .el-checkbox__label {
        border: 1px solid #1C57AF;
        color: #1C57AF;
      }
    }
  }

  &.readonly {
    .el-checkbox__input.is-disabled + span.el-checkbox__label {
      cursor: auto;
    }
  }
}
</style>