import drag from "./drag";
import Vue  from "vue";

const install = function (Vue) {
  Vue.directive("el-drag-dialog", drag);
};

window["el-drag-dialog"] = drag;
Vue.use(install); // eslint-disable-line

drag.install = install;
export default drag;
