<template>
  <a v-if="isExternalLink" v-bind="$attrs" :href="to" target="_blank">
    <slot/>
  </a>
  <!--suppress RequiredAttributes -->
  <router-link
      :to="disabled ? $route : toResult"
      :class="FILL"
      v-else
      v-on="$attrs"
      v-bind="$attrs">
    <slot/>
  </router-link>
</template>

<script>

export default {
  name: "AppLink",

  props: {
    name: String,
    params: Object,
    query: Object,
    to: [String, Object],
    fill: Boolean,
    disabled: Boolean,
  },

  computed: {
    FILL() {
      if (this.fill) {
        return "flex center h-p-100";
      }
    },
    toResult() {
      if (!!this.to) {
        return this.to;
      }

      return {
        name: this.name,
        params: this.params,
        query: this.query,
      };
    },
    isExternalLink() {
      return typeof this.to === "string" && this.to.startsWith("http");
    },
  },
};
</script>
