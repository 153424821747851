import Vue                     from "vue";
import TableSpecificationsEnum from "@/components/nbsExtend/nbsExtendTableDefine";
import {requestManage}         from "@/utils/request";

Vue.prototype.seekParentVNode = function (name) {
  let temp = this;

  while (temp) {
    if (temp.$options.name === name) {
      return temp;
    }
    temp = temp.$parent;
  }
};

Vue.mixin({
  data() {
    return {
      TSE: TableSpecificationsEnum,
    };
  },
  computed: {
    IS_DEVELOPMENT() {
      return process.env.NODE_ENV === "development";
    },
    RequestManage() {
      return requestManage;
    },
    RoutePage() {
      let name = this.$route.name;
      const match = this.$route.matched.find(x => x.name === name);

      if (match) {
        name = match.components?.default?.name;
      }
      if (name === this.$options.name) {
        return this;
      }

      return this.seekParentVNode(name) || {};
    },
  },
  /*beforeRouteLeave() {
    requestManage.clear();
  },*/
  methods: {
    setItemByUser(key, value) {
      sessionStorage.setItem((this.$store.getters.user.UserId || "") + "/" + key, value);
    },
    getItemByUser(key) {
      return sessionStorage.getItem((this.$store.getters.user.UserId || "") + "/" + key);
    },
    removeItemByUser(key){
      return sessionStorage.removeItem((this.$store.getters.user.UserId || "") + "/" + key)
    },
    async yunConfirm(title, message, options) {
      return new Promise(async resolve => {
        try {
          await this.$confirm(title, message, {
            customClass: "yun-extend-confirm-dialog-style",
            ...options,
          });
          resolve(true);
        } catch (e) {
          resolve(false);
        }
      });
    },
  },
});

export {default as requestAnimation} from "./requestAnimation";
export {default as resize}           from "./resize";
export {default as model}            from "./model";
export {default as rules}            from "./rules";
export {default as commonAnimate}            from "./commonAnimate";
